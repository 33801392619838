module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-28877178-1"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/home/runner/work/brandwell-gatsby/brandwell-gatsby/src/layouts/main.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"ns":["other","translation"],"defaultNS":"translation","localeJsonSourceName":"locales","languages":["pl","en"],"defaultLanguage":"pl","siteUrl":"https://brandwell.pl","generateDefaultLanguagePage":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang/portfolio/:name","getLanguageFromPath":true,"excludeLanguages":["pl","en"]},{"matchPath":"/static/","getLanguageFromPath":false,"excludeLanguages":["pl","en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
