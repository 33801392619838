import React from "react";
import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";

const Index = () => {
  return (
    <>
      {/*<DesktopNavigation />*/}
      <MobileNavigation />
    </>
  );
};

export default Index;
