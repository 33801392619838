// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-nas-jsx": () => import("./../../../src/pages/o_nas.jsx" /* webpackChunkName: "component---src-pages-o-nas-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-strategia-jsx": () => import("./../../../src/pages/strategia.jsx" /* webpackChunkName: "component---src-pages-strategia-jsx" */),
  "component---src-templates-project-template-jsx": () => import("./../../../src/templates/ProjectTemplate.jsx" /* webpackChunkName: "component---src-templates-project-template-jsx" */)
}

